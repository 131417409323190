//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SectionComponent from '@/components/Section.vue';
import PickerColor from '@/components/tools/PickerColor.vue';
export default {
  components: { SectionComponent, PickerColor },

  props: {
    settingOptions: { type: Object, require: true, default: () => ({}) }
  },

  data() {
    return {
      expanded: this.$ls.get('uv-exp-modal-tts-section') ?? false,
      formatDateOptions: ['YYYY/MM/DD', 'YYYY/MM/DD HH:mm'],
      thumbnailOptions: [
        { label: 'Voice code', value: 'voice_code' },
        { label: 'Photo, image', value: 'photo' }
      ]
    };
  },

  computed: {
    ttsSetting() {
      return this.settingOptions?.tts_setting || {};
    },
    fontColor() {
      return this.ttsSetting?.font_color || '#000000';
    },
    bgColor() {
      return this.ttsSetting?.bg_color || '#F0F0F0';
    },
    showTitle() {
      return this.ttsSetting?.show_title ?? true;
    },
    showSharing() {
      return this.ttsSetting?.show_sharing ?? true;
    },
    showPrintPDF() {
      return this.ttsSetting?.show_print_pdf ?? true;
    },

    // ToDo: Thumbnail setting
    thumbnailSetting() {
      return this.ttsSetting?.thumbnail || {};
    },
    showThumbnail() {
      return this.thumbnailSetting?.show_thumbnail ?? true;
    },
    thumbnailType() {
      return this.thumbnailSetting?.thumbnail_type || 'voice_code';
    },

    // ToDo: Created date setting
    createdDateSetting() {
      return this.ttsSetting?.created_date || {};
    },
    showCreatedDate() {
      return this.createdDateSetting?.show_created_date ?? true;
    },
    formatCreatedDate() {
      return this.createdDateSetting?.format_created_date || 'YYYY/MM/DD';
    },

    // ToDo: Category setting
    categorySetting() {
      return this.ttsSetting?.category || {};
    },
    showCategory() {
      return this.categorySetting?.show_category ?? true;
    }
  },

  methods: {
    toggleExpanded() {
      this.expanded = !this.expanded;
      this.$ls.set('uv-exp-modal-tts-section', this.expanded);
    },

    // Todo: Change tts setting
    changeTTSSetting(value) {
      this.updateSettingOptions({ tts_setting: { ...this.ttsSetting, ...value } });
    },
    changeFontColor(value) {
      this.changeTTSSetting({ font_color: value });
    },
    changeBgColor(value) {
      this.changeTTSSetting({ bg_color: value });
    },
    toggleTitle({ target }) {
      this.changeTTSSetting({ show_title: target.checked });
    },
    toggleSharing({ target }) {
      this.changeTTSSetting({ show_sharing: target.checked });
    },
    togglePrintPDF({ target }) {
      this.changeTTSSetting({ show_print_pdf: target.checked });
    },

    // ToDo: Change thumbnail setting
    changeThumbnailSetting(value) {
      this.changeTTSSetting({ thumbnail: { ...this.thumbnailSetting, ...value } });
    },
    toggleThumbnail({ target }) {
      this.changeThumbnailSetting({ show_thumbnail: target.checked });
    },
    changeThumbnailType(value) {
      this.changeThumbnailSetting({ thumbnail_type: value });
    },

    // ToDo: Change created date setting
    changeCreatedDateSetting(value) {
      this.changeTTSSetting({ created_date: { ...this.createdDateSetting, ...value } });
    },
    toggleCreatedDate({ target }) {
      this.changeCreatedDateSetting({ show_created_date: target.checked });
    },
    changeFormatCreatedDate(value) {
      this.changeCreatedDateSetting({ format_created_date: value });
    },

    // ToDo: Change category setting
    changeCategorySetting(value) {
      this.changeTTSSetting({ category: { ...this.categorySetting, ...value } });
    },
    toggleCategory({ target }) {
      this.changeCategorySetting({ show_category: target.checked });
    },

    // ToDo: Update setting options
    updateSettingOptions(value) {
      this.$emit('update-setting-options', value);
    }
  }
};
