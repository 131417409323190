//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SectionComponent from '@/components/Section.vue';
import PickerColor from '@/components/tools/PickerColor.vue';
import { DEFAULT_PAGE_SETTING } from '@/enum/index';

export default {
  components: { SectionComponent, PickerColor },

  props: {
    settingOptions: { type: Object, require: true, default: () => ({}) }
  },

  data() {
    return {
      formatDateOptions: ['YYYY/MM/DD', 'YYYY/MM/DD HH:mm'],
      thumbnailOptions: [
        { label: 'Voice code', value: 'voice_code' },
        { label: 'Photo, image', value: 'photo' }
      ],
      expanded: this.$ls.get('uv-exp-item-display-section') ?? false,
      tagFontSizeOptions: [
        { label: 'Small', value: 'small' },
        { label: 'Medium', value: 'medium' },
        { label: 'Large', value: 'large' }
      ],
      tagAlignOptions: [
        { label: 'Left', value: 'left' },
        { label: 'Right', value: 'right' }
      ]
    };
  },

  computed: {
    pageTemplate() {
      return this.settingOptions?.page_template || 'card_view';
    },
    isCardList() {
      return this.pageTemplate === 'card_list';
    },

    // ToDo: Item setting
    itemSetting() {
      return this.settingOptions?.item_setting || {};
    },

    // ToDo: Item title setting
    itemTitleColor() {
      return this.itemSetting?.title_color || '#000000';
    },
    itemBgColor() {
      return this.itemSetting?.bg_color || '#f9f9f9';
    },

    // ToDo: Created date setting
    createdDateSetting() {
      return this.itemSetting?.created_date || {};
    },
    showCreatedDate() {
      return this.createdDateSetting?.show_created_date ?? true;
    },
    formatCreatedDate() {
      return this.createdDateSetting?.format_created_date || 'YYYY/MM/DD';
    },

    // ToDo: Thumbnail setting
    thumbnailSetting() {
      return this.itemSetting?.thumbnail || {};
    },
    showThumbnail() {
      return this.thumbnailSetting?.show_thumbnail ?? true;
    },
    thumbnailType() {
      return this.thumbnailSetting?.thumbnail_type || 'voice_code';
    },
    thumbnailAspectRatio() {
      return this.thumbnailSetting?.aspect_ratio || 'original';
    },

    // ToDo: Category setting
    categorySetting() {
      return {
        ...DEFAULT_PAGE_SETTING?.item_setting?.category,
        ...this.itemSetting?.category
      };
    },
    showCategory() {
      return this.categorySetting?.show_category;
    },
    categoryHierarchy() {
      return this.categorySetting?.category_hierarchy;
    },
    speakCategoryTag() {
      return this.categorySetting?.speak_category_tag;
    },

    // ToDo: Tag setting
    tagSetting() {
      return {
        ...DEFAULT_PAGE_SETTING?.item_setting?.tag,
        ...this.itemSetting?.tag
      };
    },
    showTag() {
      return this.tagSetting?.show_tag ?? true;
    },
    speakTag() {
      return this.tagSetting?.speak_tag ?? true;
    },
    tagFontColor() {
      return this.tagSetting?.font_color || '#000000';
    },
    tagBgColor() {
      return this.tagSetting?.bg_color || '#F0F0F0';
    },
    tagFontSize() {
      return this.tagSetting?.font_size || 'medium';
    },
    tagAlign() {
      return this.tagSetting?.align || 'left';
    }
  },

  methods: {
    toggleExpanded() {
      this.expanded = !this.expanded;
      this.$ls.set('uv-exp-item-display-section', this.expanded);
    },

    // ToDo: Change item setting
    changeItemSetting(value) {
      this.updateSettingOptions({ item_setting: { ...this.itemSetting, ...value } });
    },

    // ToDo: Change title setting
    changeItemTitleColor(value) {
      this.changeItemSetting({ title_color: value });
    },
    changeItemBgColor(value) {
      this.changeItemSetting({ bg_color: value });
    },

    // ToDo: Change created date setting
    changeCreatedDateSetting(value) {
      this.changeItemSetting({ created_date: { ...this.createdDateSetting, ...value } });
    },
    toggleCreatedDate({ target }) {
      this.changeCreatedDateSetting({ show_created_date: target.checked });
    },
    changeFormatCreatedDate(value) {
      this.changeCreatedDateSetting({ format_created_date: value });
    },

    // ToDo: Change thumbnail setting
    changeThumbnailSetting(value) {
      this.changeItemSetting({ thumbnail: { ...this.thumbnailSetting, ...value } });
    },
    toggleShowThumbnail({ target }) {
      this.changeThumbnailSetting({ show_thumbnail: target.checked });
    },
    changeThumbnailType(value) {
      this.changeThumbnailSetting({ thumbnail_type: value });
    },

    // ToDo: Change category setting
    changeCategorySetting(value) {
      this.changeItemSetting({ category: { ...this.categorySetting, ...value } });
    },
    toggleShowCategory({ target }) {
      this.changeCategorySetting({ show_category: target.checked });
    },
    changeCategoryHierarchy(value) {
      this.changeCategorySetting({ category_hierarchy: value });
    },
    toggleSpeakCategoryTag({ target }) {
      this.changeCategorySetting({ speak_category_tag: target.checked });
    },

    // Todo: Change tag setting
    changeTagSetting(value) {
      this.changeItemSetting({ tag: { ...this.tagSetting, ...value } });
    },
    toggleShowTag({ target }) {
      this.changeTagSetting({ show_tag: target.checked });
    },
    toggleSpeakTag({ target }) {
      this.changeTagSetting({ speak_tag: target.checked });
    },
    changeTagFontColor(value) {
      this.changeTagSetting({ font_color: value });
    },
    changeTagBgColor(value) {
      this.changeTagSetting({ bg_color: value });
    },
    changeTagAlign(value) {
      this.changeTagSetting({ align: value });
    },
    changeTagFontSize(value) {
      this.changeTagSetting({ font_size: value });
    },

    // ToDo: Update setting options
    updateSettingOptions(value) {
      this.$emit('update-setting-options', value);
    }
  }
};
