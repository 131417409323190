var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section-component",
    {
      attrs: { topic: _vm.$t("Search option"), expanded: _vm.expanded },
      on: { toggleExpanded: _vm.toggleExpanded }
    },
    [
      _c(
        "a-row",
        { attrs: { gutter: [32, 16] } },
        [
          _c(
            "a-col",
            { attrs: { xs: 24, lg: 8 } },
            [
              _c("p", { staticClass: "body font-bold" }, [
                _vm._v(_vm._s(_vm.$t("Search setting")))
              ]),
              _c(
                "a-row",
                { attrs: { gutter: [16, 8], type: "flex", align: "middle" } },
                [
                  _c("a-col", { attrs: { xs: 12, lg: 14 } }, [
                    _vm._v(_vm._s(_vm.$t("Display keyword search")))
                  ]),
                  _c(
                    "a-col",
                    { attrs: { xs: 12, lg: 10 } },
                    [
                      _c("a-checkbox", {
                        attrs: { checked: _vm.showKeywordSearch },
                        on: { change: _vm.toggleKeywordSearch }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: [16, 8], type: "flex", align: "middle" } },
                [
                  _c("a-col", { attrs: { xs: 12, lg: 14 } }, [
                    _vm._v(_vm._s(_vm.$t("Display category search")))
                  ]),
                  _c(
                    "a-col",
                    { attrs: { xs: 12, lg: 10 } },
                    [
                      _c("a-checkbox", {
                        attrs: { checked: _vm.showCategorySearch },
                        on: { change: _vm.toggleCategorySearch }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: [16, 8], type: "flex", align: "middle" } },
                [
                  _c(
                    "a-col",
                    { attrs: { xs: 12, lg: { offset: 1, span: 13 } } },
                    [_vm._v(" " + _vm._s(_vm.$t("Show numbering")) + " ")]
                  ),
                  _c(
                    "a-col",
                    { attrs: { xs: 12, lg: 10 } },
                    [
                      _c("a-checkbox", {
                        attrs: { checked: _vm.showNumbering },
                        on: { change: _vm.toggleNumbering }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: [16, 8], type: "flex", align: "middle" } },
                [
                  _c(
                    "a-col",
                    { attrs: { xs: 12, lg: { offset: 1, span: 13 } } },
                    [_vm._v(" " + _vm._s(_vm.$t("Search category by")) + " ")]
                  ),
                  _c(
                    "a-col",
                    { attrs: { xs: 12, lg: 10 } },
                    [
                      _c(
                        "a-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { value: _vm.searchBy },
                          on: { change: _vm.changeSearchBy }
                        },
                        _vm._l(_vm.searchOptions, function(item) {
                          return _c(
                            "a-select-option",
                            { key: item.value, attrs: { value: item.value } },
                            [_vm._v(" " + _vm._s(item.label) + " ")]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: [16, 8], type: "flex", align: "middle" } },
                [
                  _c(
                    "a-col",
                    { attrs: { xs: 12, lg: { offset: 1, span: 13 } } },
                    [_vm._v(" " + _vm._s(_vm.$t("Category hierarchy")) + " ")]
                  ),
                  _c(
                    "a-col",
                    { attrs: { xs: 12, lg: 10 } },
                    [
                      _c(
                        "a-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { value: _vm.categoryHierarchy },
                          on: { change: _vm.changeCategoryHierarchy }
                        },
                        _vm._l(_vm.categoryHierarchyOptions, function(item) {
                          return _c(
                            "a-select-option",
                            { key: item.value, attrs: { value: item.value } },
                            [_vm._v(" " + _vm._s(item.label) + " ")]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: [16, 8], type: "flex", align: "middle" } },
                [
                  _c(
                    "a-col",
                    { attrs: { xs: 12, lg: { offset: 1, span: 23 } } },
                    [
                      _c("div", [_vm._v(_vm._s(_vm.$t("Custom title")))]),
                      _c(
                        "div",
                        {
                          staticClass: "ml-xs",
                          staticStyle: {
                            "font-size": "0.8rem",
                            color: "#9b9b9b"
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "You can input a custom title for the filter category"
                                )
                              ) +
                              " "
                          )
                        ]
                      )
                    ]
                  ),
                  _c(
                    "a-col",
                    { attrs: { xs: 12, lg: { offset: 1, span: 23 } } },
                    [
                      _c("a-input-search", {
                        staticStyle: { width: "100%" },
                        attrs: { "enter-button": "OK" },
                        on: { search: _vm.changeCategorySearchTitle },
                        model: {
                          value: _vm.categorySearchTitle,
                          callback: function($$v) {
                            _vm.categorySearchTitle = $$v
                          },
                          expression: "categorySearchTitle"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { xs: 24, lg: 8 } },
            [
              _c("p", { staticClass: "body font-bold" }, [
                _vm._v(_vm._s(_vm.$t("Color setting")))
              ]),
              _c(
                "a-row",
                { attrs: { gutter: [8, 16], type: "flex", align: "middle" } },
                [
                  _c("a-col", { attrs: { xs: 12, lg: 14 } }, [
                    _vm._v(
                      " " + _vm._s(_vm.$t("Font color for search title")) + " "
                    )
                  ]),
                  _c(
                    "a-col",
                    { attrs: { xs: 12, lg: 10 } },
                    [
                      _c("picker-color", {
                        staticStyle: { width: "100%" },
                        attrs: { color: _vm.searchTitleColor },
                        on: { change: _vm.changeSearchTitleColor }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: [8, 16], type: "flex", align: "middle" } },
                [
                  _c("a-col", { attrs: { xs: 12, lg: 14 } }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("Background color for root category")) +
                        " "
                    )
                  ]),
                  _c(
                    "a-col",
                    { attrs: { xs: 12, lg: 10 } },
                    [
                      _c("picker-color", {
                        staticStyle: { width: "100%" },
                        attrs: { color: _vm.megaRootBgColor },
                        on: { change: _vm.changeMegaRootBgColor }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: [8, 16], type: "flex", align: "middle" } },
                [
                  _c("a-col", { attrs: { xs: 12, lg: 14 } }, [
                    _vm._v(
                      " " + _vm._s(_vm.$t("Font color for root category")) + " "
                    )
                  ]),
                  _c(
                    "a-col",
                    { attrs: { xs: 12, lg: 10 } },
                    [
                      _c("picker-color", {
                        staticStyle: { width: "100%" },
                        attrs: { color: _vm.megaRootFontColor },
                        on: { change: _vm.changeMegaRootFontColor }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: [8, 16], type: "flex", align: "middle" } },
                [
                  _c("a-col", { attrs: { xs: 12, lg: 14 } }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("Background color for sub category")) +
                        " "
                    )
                  ]),
                  _c(
                    "a-col",
                    { attrs: { xs: 12, lg: 10 } },
                    [
                      _c("picker-color", {
                        staticStyle: { width: "100%" },
                        attrs: { color: _vm.megaSubBgColor },
                        on: { change: _vm.changeMegaSubBgColor }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: [8, 16], type: "flex", align: "middle" } },
                [
                  _c("a-col", { attrs: { xs: 12, lg: 14 } }, [
                    _vm._v(
                      " " + _vm._s(_vm.$t("Font color for sub category")) + " "
                    )
                  ]),
                  _c(
                    "a-col",
                    { attrs: { xs: 12, lg: 10 } },
                    [
                      _c("picker-color", {
                        staticStyle: { width: "100%" },
                        attrs: { color: _vm.megaSubFontColor },
                        on: { change: _vm.changeMegaSubFontColor }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }