var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section-component",
    {
      attrs: { topic: _vm.$t("Item display option"), expanded: _vm.expanded },
      on: { toggleExpanded: _vm.toggleExpanded }
    },
    [
      _c(
        "a-row",
        { attrs: { gutter: [32, 32] } },
        [
          _c(
            "a-col",
            { attrs: { xs: 24, lg: 8 } },
            [
              _c("p", { staticClass: "body font-bold" }, [
                _vm._v(_vm._s(_vm.$t("Created date setting")))
              ]),
              _c(
                "a-row",
                { attrs: { gutter: [16, 8], type: "flex", align: "middle" } },
                [
                  _c("a-col", { attrs: { xs: 12, lg: 14 } }, [
                    _vm._v(" " + _vm._s(_vm.$t("Show created date")) + " ")
                  ]),
                  _c(
                    "a-col",
                    { attrs: { xs: 12, lg: 10 } },
                    [
                      _c("a-checkbox", {
                        attrs: { checked: _vm.showCreatedDate },
                        on: { change: _vm.toggleCreatedDate }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: [16, 8], type: "flex", align: "middle" } },
                [
                  _c("a-col", { attrs: { xs: 12, lg: 14 } }, [
                    _vm._v(" " + _vm._s(_vm.$t("Format created date")) + " ")
                  ]),
                  _c(
                    "a-col",
                    { attrs: { xs: 12, lg: 10 } },
                    [
                      _c(
                        "a-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            value: _vm.formatCreatedDate,
                            disabled: !_vm.showCreatedDate
                          },
                          on: { change: _vm.changeFormatCreatedDate }
                        },
                        _vm._l(_vm.formatDateOptions, function(item) {
                          return _c(
                            "a-select-option",
                            { key: item, attrs: { value: item } },
                            [_vm._v(" " + _vm._s(item) + " ")]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { xs: 24, lg: 8 } },
            [
              _c("p", { staticClass: "body font-bold" }, [
                _vm._v(_vm._s(_vm.$t("Thumbnail setting")))
              ]),
              _c(
                "a-row",
                { attrs: { gutter: [16, 8], type: "flex", align: "middle" } },
                [
                  _c("a-col", { attrs: { xs: 12, lg: 14 } }, [
                    _vm._v(" " + _vm._s(_vm.$t("Show thumbnail")) + " ")
                  ]),
                  _c(
                    "a-col",
                    { attrs: { xs: 12, lg: 10 } },
                    [
                      _c("a-checkbox", {
                        attrs: { checked: _vm.showThumbnail },
                        on: { change: _vm.toggleShowThumbnail }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: [16, 8], type: "flex", align: "middle" } },
                [
                  _c("a-col", { attrs: { xs: 12, lg: 14 } }, [
                    _vm._v(" " + _vm._s(_vm.$t("Thumbnail type")) + " ")
                  ]),
                  _c(
                    "a-col",
                    { attrs: { xs: 12, lg: 10 } },
                    [
                      _c(
                        "a-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            value: _vm.thumbnailType,
                            disabled: !_vm.showThumbnail
                          },
                          on: { change: _vm.changeThumbnailType }
                        },
                        _vm._l(_vm.thumbnailOptions, function(item) {
                          return _c(
                            "a-select-option",
                            { key: item.value, attrs: { value: item.value } },
                            [_vm._v(" " + _vm._s(_vm.$t(item.label)) + " ")]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { xs: 24, lg: 8 } },
            [
              _c("p", { staticClass: "body font-bold" }, [
                _vm._v(_vm._s(_vm.$t("Category setting")))
              ]),
              _c(
                "a-row",
                { attrs: { gutter: [16, 8], type: "flex", align: "middle" } },
                [
                  _c("a-col", { attrs: { xs: 12, lg: 14 } }, [
                    _vm._v(" " + _vm._s(_vm.$t("Show category tag")) + " ")
                  ]),
                  _c(
                    "a-col",
                    { attrs: { xs: 12, lg: 10 } },
                    [
                      _c("a-checkbox", {
                        attrs: { checked: _vm.showCategory },
                        on: { change: _vm.toggleShowCategory }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              ["card_list", "card_view", "list_view"].includes(_vm.pageTemplate)
                ? _c(
                    "a-row",
                    {
                      attrs: { gutter: [16, 8], type: "flex", align: "middle" }
                    },
                    [
                      _c("a-col", { attrs: { xs: 12, lg: 14 } }, [
                        _vm._v(" " + _vm._s(_vm.$t("Category hierarchy")) + " ")
                      ]),
                      _c(
                        "a-col",
                        { attrs: { xs: 12, lg: 10 } },
                        [
                          _c(
                            "a-select",
                            {
                              staticStyle: { "min-width": "60px" },
                              attrs: { value: _vm.categoryHierarchy },
                              on: { change: _vm.changeCategoryHierarchy }
                            },
                            [
                              _c("a-select-option", { attrs: { value: 1 } }, [
                                _vm._v("1")
                              ]),
                              _c("a-select-option", { attrs: { value: 2 } }, [
                                _vm._v("2")
                              ]),
                              _c("a-select-option", { attrs: { value: 3 } }, [
                                _vm._v("3")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              ["card_list", "card_view", "list_view"].includes(_vm.pageTemplate)
                ? _c(
                    "a-row",
                    {
                      attrs: { gutter: [16, 8], type: "flex", align: "middle" }
                    },
                    [
                      _c("a-col", { attrs: { xs: 12, lg: 14 } }, [
                        _vm._v(" " + _vm._s(_vm.$t("Speak category tag")) + " ")
                      ]),
                      _c(
                        "a-col",
                        { attrs: { xs: 12, lg: 10 } },
                        [
                          _c("a-checkbox", {
                            attrs: { checked: _vm.speakCategoryTag },
                            on: { change: _vm.toggleSpeakCategoryTag }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-row",
        { attrs: { gutter: [32, 0] } },
        [
          _c(
            "a-col",
            { attrs: { xs: 24, lg: 8 } },
            [
              _c("p", { staticClass: "body font-bold" }, [
                _vm._v(_vm._s(_vm.$t("Tag setting")))
              ]),
              _c(
                "a-row",
                { attrs: { gutter: [16, 8], type: "flex", align: "middle" } },
                [
                  _c("a-col", { attrs: { xs: 12, lg: 14 } }, [
                    _vm._v(" " + _vm._s(_vm.$t("Show tag")) + " ")
                  ]),
                  _c(
                    "a-col",
                    { attrs: { xs: 12, lg: 10 } },
                    [
                      _c("a-checkbox", {
                        attrs: { checked: _vm.showTag },
                        on: { change: _vm.toggleShowTag }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: [16, 8], type: "flex", align: "middle" } },
                [
                  _c("a-col", { attrs: { xs: 12, lg: 14 } }, [
                    _vm._v(" " + _vm._s(_vm.$t("Speak tag")) + " ")
                  ]),
                  _c(
                    "a-col",
                    { attrs: { xs: 12, lg: 10 } },
                    [
                      _c("a-checkbox", {
                        attrs: { checked: _vm.speakTag },
                        on: { change: _vm.toggleSpeakTag }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: [16, 8], type: "flex", align: "middle" } },
                [
                  _c("a-col", { attrs: { xs: 12, lg: 14 } }, [
                    _vm._v(" " + _vm._s(_vm.$t("Text align")) + " ")
                  ]),
                  _c(
                    "a-col",
                    { attrs: { xs: 12, lg: 10 } },
                    [
                      _c(
                        "a-select",
                        {
                          staticStyle: { flex: "1", width: "100%" },
                          attrs: { value: _vm.tagAlign },
                          on: { change: _vm.changeTagAlign }
                        },
                        _vm._l(_vm.tagAlignOptions, function(align) {
                          return _c(
                            "a-select-option",
                            { key: align.value, attrs: { value: align.value } },
                            [_vm._v(" " + _vm._s(_vm.$t(align.label)) + " ")]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: [16, 8], type: "flex", align: "middle" } },
                [
                  _c("a-col", { attrs: { xs: 12, lg: 14 } }, [
                    _vm._v(" " + _vm._s(_vm.$t("Font size")) + " ")
                  ]),
                  _c(
                    "a-col",
                    { attrs: { xs: 12, lg: 10 } },
                    [
                      _c(
                        "a-select",
                        {
                          staticStyle: { flex: "1", width: "100%" },
                          attrs: { value: _vm.tagFontSize },
                          on: { change: _vm.changeTagFontSize }
                        },
                        _vm._l(_vm.tagFontSizeOptions, function(item) {
                          return _c(
                            "a-select-option",
                            { key: item.value, attrs: { value: item.value } },
                            [_vm._v(" " + _vm._s(_vm.$t(item.label)) + " ")]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { xs: 24, lg: 8 } },
            [
              _c("p", { staticClass: "body font-bold" }, [
                _vm._v(_vm._s(_vm.$t("Color setting")))
              ]),
              _c(
                "a-row",
                { attrs: { gutter: [16, 8], type: "flex", align: "middle" } },
                [
                  _c("a-col", { attrs: { xs: 12, lg: 14 } }, [
                    _vm._v(_vm._s(_vm.$t("Item title color")))
                  ]),
                  _c(
                    "a-col",
                    { attrs: { xs: 12, lg: 10 } },
                    [
                      _c("picker-color", {
                        staticStyle: { width: "100%" },
                        attrs: { color: _vm.itemTitleColor },
                        on: { change: _vm.changeItemTitleColor }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.isCardList
                ? _c(
                    "a-row",
                    {
                      attrs: { gutter: [16, 8], type: "flex", align: "middle" }
                    },
                    [
                      _c("a-col", { attrs: { xs: 12, lg: 14 } }, [
                        _vm._v(_vm._s(_vm.$t("Item background")))
                      ]),
                      _c(
                        "a-col",
                        { attrs: { xs: 12, lg: 10 } },
                        [
                          _c("picker-color", {
                            staticStyle: { width: "100%" },
                            attrs: { color: _vm.itemBgColor },
                            on: { change: _vm.changeItemBgColor }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "a-row",
                { attrs: { gutter: [16, 8], type: "flex", align: "middle" } },
                [
                  _c("a-col", { attrs: { xs: 12, lg: 14 } }, [
                    _vm._v(_vm._s(_vm.$t("Font color for tag")))
                  ]),
                  _c(
                    "a-col",
                    { attrs: { xs: 12, lg: 10 } },
                    [
                      _c("picker-color", {
                        staticStyle: { width: "100%" },
                        attrs: { color: _vm.tagFontColor },
                        on: { change: _vm.changeTagFontColor }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: [16, 8], type: "flex", align: "middle" } },
                [
                  _c("a-col", { attrs: { xs: 12, lg: 14 } }, [
                    _vm._v(_vm._s(_vm.$t("Background color for tag")))
                  ]),
                  _c(
                    "a-col",
                    { attrs: { xs: 12, lg: 10 } },
                    [
                      _c("picker-color", {
                        staticStyle: { width: "100%" },
                        attrs: { color: _vm.tagBgColor },
                        on: { change: _vm.changeTagBgColor }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }