var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section-component",
    {
      attrs: { topic: _vm.$t("Publish info"), expanded: _vm.expanded },
      on: { toggleExpanded: _vm.toggleExpanded }
    },
    [
      _c(
        "a-row",
        { attrs: { gutter: [32, 16] } },
        [
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _c("div", { staticClass: "important-note mb-xs" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("*This is the URL of the public version")) +
                    " "
                )
              ]),
              _c("span", { staticClass: "body font-bold" }, [
                _vm._v(_vm._s(_vm.$t("Publish URL setting")))
              ]),
              _c(
                "a-row",
                {
                  attrs: {
                    gutter: [16, 16],
                    type: "flex",
                    justify: "space-between",
                    align: "middle"
                  }
                },
                [
                  _c("a-col", { attrs: { md: 24, lg: 12 } }, [
                    _c(
                      "div",
                      { staticClass: "content-start" },
                      [
                        _c(
                          "a-input-search",
                          {
                            staticStyle: { flex: "1" },
                            attrs: {
                              size: "large",
                              disabled: _vm.isPrimary,
                              "enter-button": "OK"
                            },
                            on: {
                              search: _vm.changeSlug,
                              change: _vm.handleOnChangeSlug
                            },
                            model: {
                              value: _vm.newSlug,
                              callback: function($$v) {
                                _vm.newSlug = $$v
                              },
                              expression: "newSlug"
                            }
                          },
                          [
                            _c(
                              "template",
                              { slot: "addonBefore" },
                              [
                                _c(
                                  "a-tooltip",
                                  {
                                    attrs: {
                                      placement: "topLeft",
                                      title: _vm.$t("Go to edit sub domain"),
                                      "arrow-point-at-center": ""
                                    }
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          cursor: "pointer",
                                          "font-weight": "bold"
                                        },
                                        on: { click: _vm.goToEditProject }
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(_vm.baseUrl + "/") + " "
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          2
                        )
                      ],
                      1
                    )
                  ]),
                  _c(
                    "a-col",
                    { attrs: { md: 24, lg: 12 } },
                    [
                      _c(
                        "a-button",
                        {
                          staticClass: "mr-xs",
                          attrs: { size: "large", type: "primary" },
                          on: { click: _vm.copyPublishURL }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("Copy")) + " ")]
                      ),
                      _c(
                        "a-button",
                        {
                          attrs: { size: "large", disabled: !_vm.isPublic },
                          on: { click: _vm.openProject }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("Open")) + " ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _c("span", { staticClass: "body font-bold" }, [
                _vm._v(_vm._s(_vm.$t("Embed code")))
              ]),
              _c(
                "a-row",
                {
                  attrs: {
                    gutter: [16, 16],
                    type: "flex",
                    justify: "space-between",
                    align: "middle"
                  }
                },
                [
                  _c(
                    "a-col",
                    { attrs: { md: 24, lg: 12 } },
                    [
                      _c("a-textarea", {
                        staticStyle: { width: "100%" },
                        attrs: { value: _vm.iframeCode },
                        on: { change: _vm.changeIframeCode }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { md: 24, lg: 12 } },
                    [
                      _c(
                        "a-button",
                        {
                          attrs: { size: "large", type: "primary" },
                          on: { click: _vm.copyIframeCode }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("Copy")) + " ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }