//
//
//
//
//
//
//
//
//
//
//
//

import SectionComponent from '@/components/Section.vue';

export default {
  components: { SectionComponent },

  props: {
    page: { type: Object, default: () => ({}) }
  },

  data() {
    return {};
  },

  computed: {
    isPublic() {
      return this.page?.ispublic;
    }
  },
  watch: {},
  methods: {}
};
