var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section-component",
    {
      attrs: { topic: _vm.$t("Page template"), expanded: _vm.expanded },
      on: { toggleExpanded: _vm.toggleExpanded }
    },
    [
      _c(
        "a-row",
        { attrs: { gutter: [32, 32] } },
        _vm._l(_vm.templateOptions, function(item) {
          return _c("a-col", { key: item.key, attrs: { xs: 12, lg: 4 } }, [
            _c(
              "div",
              { staticClass: "page-template" },
              [
                _c("div", { staticClass: "page-template__title" }, [
                  _vm._v(" " + _vm._s(item.title) + " ")
                ]),
                _c(
                  "div",
                  {
                    staticClass: "page-template__body",
                    attrs: { role: "button" },
                    on: {
                      click: function($event) {
                        return _vm.changePageTemplate(item.key)
                      }
                    }
                  },
                  [
                    _c("img", {
                      style: {
                        opacity: _vm.pageTemplate === item.key ? "0.7" : 1
                      },
                      attrs: {
                        src: require("@/assets/images/" + item.img.url),
                        alt: item.img.alt
                      }
                    }),
                    _c("a-checkbox", {
                      attrs: { checked: _vm.pageTemplate === item.key }
                    })
                  ],
                  1
                ),
                item.key === "group_view"
                  ? [
                      _c(
                        "a-tooltip",
                        {
                          attrs: {
                            placement: "top",
                            title: _vm.$t(
                              "You can set category hierarchy to display"
                            )
                          }
                        },
                        [
                          _c("br"),
                          _c(
                            "a-input",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                disabled: "",
                                "default-value": _vm.$t("Category hierarchy")
                              }
                            },
                            [
                              _c(
                                "a-select",
                                {
                                  attrs: {
                                    slot: "addonAfter",
                                    value: _vm.categoryLevel,
                                    disabled: _vm.pageTemplate !== "group_view"
                                  },
                                  on: { change: _vm.changeCategoryLevel },
                                  slot: "addonAfter"
                                },
                                _vm._l(_vm.categoryHierarchyOptions, function(
                                  item
                                ) {
                                  return _c(
                                    "a-select-option",
                                    {
                                      key: item.value,
                                      attrs: { value: item.value }
                                    },
                                    [_vm._v(" " + _vm._s(item.label) + " ")]
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  : _vm._e()
              ],
              2
            )
          ])
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }