var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isPublic
    ? _c(
        "a-card",
        {
          attrs: {
            "head-style": { background: "antiquewhite" },
            "body-style": { background: "antiquewhite", padding: "12px" }
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "font-bold h6",
              staticStyle: { color: "#b62a2a" },
              attrs: { slot: "title" },
              slot: "title"
            },
            [
              _c("a-icon", {
                staticClass: "mr-xs",
                attrs: { type: "exclamation-circle" }
              }),
              _vm._v(
                " " + _vm._s(_vm.$t("This website is not public yet")) + " "
              )
            ],
            1
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }