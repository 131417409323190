//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import { LANGUAGES } from '@/enum';
import SectionComponent from '@/components/Section.vue';
import UploadPhoto from '@/components/spec/UploadPhoto.vue';
import PickerColor from '@/components/tools/PickerColor.vue';
import TextEditor from '@/components/tools/TextEditor.vue';
import { DEFAULT_PAGE_SETTING } from '@/enum';

export default {
  components: {
    SectionComponent,
    PickerColor,
    UploadPhoto,
    TextEditor
  },

  props: {
    pageId: { type: Number, default: 0 },
    page: { type: Object, default: () => ({}) },
    settingOptions: { type: Object, require: true, default: () => ({}) }
  },

  data() {
    return {
      sortOptions: [
        { label: 'New arrival order', value: 'created desc' },
        { label: 'Oldest first', value: 'created asc' },
        { label: 'Crawl index ASC', value: 'public_date asc' },
        { label: 'Crawl index DESC', value: 'public_date desc' }
      ],
      fontSizeOptions: [
        { label: 'X-Small', value: 'x-small' },
        { label: 'Small', value: 'small' },
        { label: 'Medium', value: 'medium' },
        { label: 'Large', value: 'large' },
        { label: 'X-Large', value: 'x-large' }
      ],
      alignOptions: [
        { label: 'Left', value: 'left' },
        { label: 'Center', value: 'center' },
        { label: 'Right', value: 'right' }
      ],
      typeOfColumnOptions: [
        { label: 'Flexible', value: 'flexible' },
        { label: 'Fixed', value: 'fixed' }
      ],
      alignCategoryItemOptions: [
        { label: this.$t('Horizontal-Vertical'), value: 'hor-ver' },
        { label: this.$t('Vertical-Horizontal'), value: 'ver-hor' }
      ],
      pageTitle: '',
      itemNaming: '',
      expanded: this.$ls.get('uv-exp-page-option-section') ?? false,
      introductionContent: null,
      showPageIntroductionEditor: false,
      logoCaption: ''
    };
  },

  watch: {
    settingOptions: {
      handler() {
        this.initSettingOptions(this.settingOptions);
      },
      immediate: true
    }
  },

  computed: {
    ...mapGetters(['currentLanguage']),
    numberOfColumnOptionsGroupView() {
      if (this.alignCategoryItem === 'ver-hor') {
        return [
          { label: '3', value: 3 },
          { label: '4', value: 4 },
          { label: '5', value: 5 },
          { label: '6', value: 6 }
        ]
      }
      return [
        { label: '1', value: 1 },
        { label: '2', value: 2 },
        { label: '3', value: 3 }
      ]
    },
    numberOfColumnOptionsCardView() {
      return [
        { label: '3', value: 3 },
        { label: '4', value: 4 },
        { label: '5', value: 5 },
        { label: '6', value: 6 },
      ]
    },
    alignCategoryItem() {
      return this.settingOptions?.align_category_item || 'hor-ver'
    },
    folderNameS3() {
      return `${process.env.VUE_APP_S3_PROJECT_THUMBNAIL}/${this.pageId}`;
    },
    langOptions() {
      return LANGUAGES[this.currentLanguage];
    },
    showPageTitle() {
      return this.settingOptions?.show_page_title ?? true;
    },
    showHeader() {
      return this.settingOptions?.show_header ?? true;
    },
    showFooter() {
      return this.settingOptions?.show_footer ?? true;
    },
    showSharing() {
      return this.settingOptions?.show_sharing ?? true;
    },
    showPrintPDF() {
      return this.settingOptions?.show_print_pdf ?? true;
    },
    pageLang() {
      return this.settingOptions?.language || 'jpn';
    },
    order() {
      return this.settingOptions?.order || 'created desc';
    },
    pageTitleColor() {
      return this.settingOptions?.page_title_color || '#000000';
    },
    bgColor() {
      return this.settingOptions?.bg_color || '#c9daf8';
    },
    headingColor() {
      return this.settingOptions?.heading_color || '#4a86e8';
    },
    faviconURL() {
      return this.settingOptions?.favicon || '';
    },

    // ToDo: Introduction setting
    introductionSetting() {
      return this.settingOptions?.introduction || { show_introduction: false, content: null };
    },
    showIntroduction() {
      return this.introductionSetting?.show_introduction ?? false;
    },

    // ToDo: Menu setting
    menuSetting() {
      return this.settingOptions?.menu_setting || { type: 'icon' };
    },
    menuType() {
      return this.menuSetting?.type || 'icon';
    },

    // ToDo: Page logo setting
    pageLogoSetting() {
      const headerTitle = this.settingOptions?.header_title || '';
      const pageName = this.page?.name || '';
      const defaultValue = {
        logo_url: '',
        show_logo: false,
        logo_caption: headerTitle || pageName || ''
      };
      return this.settingOptions?.page_logo || defaultValue;
    },
    showLogo() {
      return this.pageLogoSetting?.show_logo ?? false;
    },
    logoURL() {
      return this.pageLogoSetting?.logo_url || '';
    },

    pageTitleAlign() {
      return this.settingOptions?.page_title_align || 'center';
    },
    pageTitleFontSize() {
      return this.settingOptions?.page_title_font_size || 'medium';
    },
    pageTemplate() {
      return this.settingOptions?.page_template || 'card_view';
    },
    isCardList() {
      return this.pageTemplate === 'card_list';
    },
    isGroupView() {
      return this.pageTemplate === 'group_view'
    },
    isCardView() {
      return this.pageTemplate === 'card_view'
    },

    // Column setting
    columnSetting() {
      return this.settingOptions?.column_setting || DEFAULT_PAGE_SETTING?.column_setting;
    }
  },

  methods: {
    initSettingOptions(data) {
      this.pageTitle = data?.header_title || this.page?.name || '';
      this.itemNaming = data?.item_naming || '';
      this.introductionContent = data?.introduction?.content;
      this.logoCaption =
        data?.page_logo?.logo_caption || data?.header_title || this.page?.name || '';
    },
    toggleExpanded() {
      this.expanded = !this.expanded;
      this.$ls.set('uv-exp-page-option-section', this.expanded);
    },
    togglePageTitle({ target }) {
      this.updateSettingOptions({ show_page_title: target.checked });
    },
    toggleHeader({ target }) {
      this.updateSettingOptions({ show_header: target.checked });
    },
    toggleFooter({ target }) {
      this.updateSettingOptions({ show_footer: target.checked });
    },
    toggleSharing({ target }) {
      this.updateSettingOptions({ show_sharing: target.checked });
    },
    togglePrintPDF({ target }) {
      this.updateSettingOptions({ show_print_pdf: target.checked });
    },
    changePageLang(value) {
      this.updateSettingOptions({ language: value });
    },
    changeOrder(value) {
      this.updateSettingOptions({ order: value });
    },
    changeItemNaming(value) {
      this.updateSettingOptions({ item_naming: value });
    },
    changePageTitle(value) {
      this.updateSettingOptions({ header_title: value, is_edited_header_title: true });
    },
    changePageTitleAlign(value) {
      this.updateSettingOptions({ page_title_align: value });
    },
    changePageTitleFontSize(value) {
      this.updateSettingOptions({ page_title_font_size: value });
    },

    changePageTitleColor(value) {
      this.updateSettingOptions({ page_title_color: value });
    },
    changeBgColor(value) {
      this.updateSettingOptions({ bg_color: value });
    },
    changeHeadingColor(value) {
      this.updateSettingOptions({ heading_color: value });
    },
    changeFavicon(value) {
      this.updateSettingOptions({ favicon: value });
    },
    deleteFavicon() {
      this.updateSettingOptions({ favicon: '' });
    },

    // ToDo: Change menu setting
    changeMenuSetting(value) {
      this.updateSettingOptions({ menu_setting: { ...this.menuSetting, ...value } });
    },
    changeMenuType(value) {
      this.changeMenuSetting({ type: value });
    },

    // ToDo: Change page logo setting
    changePageLogoSetting(value) {
      const headerTitle = this.settingOptions?.header_title || '';
      const pageName = this.page?.name || '';
      const page_logo = {
        ...this.pageLogoSetting,
        ...value
      };
      if (!page_logo.logo_caption) {
        page_logo.logo_caption = headerTitle || pageName;
      }
      this.updateSettingOptions({ page_logo });
    },
    toggleLogo({ target }) {
      this.changePageLogoSetting({ show_logo: target.checked });
    },
    changeLogoURL(value) {
      this.changePageLogoSetting({ logo_url: value });
    },
    deleteLogoURL() {
      this.changePageLogoSetting({ logo_url: '' });
    },
    changeLogoCaption(value) {
      this.changePageLogoSetting({ logo_caption: value });
    },

    // ToDo: Change introduction setting
    changeIntroductionContent(value) {
      this.introductionContent = value || null;
    },
    updateIntroductionContent() {
      this.showPageIntroductionEditor = false;
      this.changeIntroductionSetting({ content: this.introductionContent });
    },
    toggleIntroduction({ target }) {
      if (target.checked) {
        this.showPageIntroductionEditor = true;
      }
      this.changeIntroductionSetting({ show_introduction: target.checked });
    },
    changeIntroductionSetting(value) {
      this.updateSettingOptions({ introduction: { ...this.introductionSetting, ...value } });
    },

    // ToDo: Change column setting
    updateColumnSetting(value) {
      this.updateSettingOptions({ column_setting: { ...this.columnSetting, ...value } });
    },
    changeColumnType(value) {
      this.updateColumnSetting({ type: value });
    },
    changeColumnNumber(value) {
      this.updateColumnSetting({ number: value });
    },
    changeAlignCategoryItem(value) {
      this.updateSettingOptions({
        align_category_item: value,
        column_setting: { ...this.columnSetting, number: value === 'hor-ver' ? 1 : 4 }
      })
    },

    updateSettingOptions(value) {
      this.$emit('update-setting-options', value);
    }
  }
};
